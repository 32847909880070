import SEO from '@connected/SEO';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';

const SectionsAsync = loadable(() => import('@components/connected/Sections'));

interface ContentPageProps {
  data: {
    contentPage: ContentModels.ContentfulHomePage;
  };
}

const ContentPage: React.FC<ContentPageProps> = ({ data }) => {
  return (
    <>
      <SEO data={data.contentPage.metadata} url={data.contentPage.url} />
      <SectionsAsync sections={data.contentPage.sections} />
    </>
  );
};

export default ContentPage;

export const query = graphql`
  query ContentPageQuery($slug: String) {
    contentPage: contentfulContentPage(slug: { eq: $slug }) {
      contentful_id
      metadata {
        ...ContentfulMetadataFragment
      }
      sections {
        ...LinkedItemsFragment
      }
      title
      useAlternateFooter
    }
  }
`;
